/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
.pane-bundle-wcm-clinical-service-group .pane-title {
  color: #666666;
  margin-bottom: 1rem; }

@media screen and (min-width: 768px) {
  .pane-bundle-wcm-clinical-service-group .fieldable-panels-pane .field-content-items {
    columns: 2; } }

.pane-bundle-wcm-clinical-service-group .fieldable-panels-pane .field-content-items .field-content-item {
  border-bottom: 1px solid #f7f7f7;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 1rem; }

.pane-clinical-services-groups .wcm-cs-tabs {
  margin: 3rem 0;
  text-align: center; }
  @media screen and (min-width: 992px) {
    .pane-clinical-services-groups .wcm-cs-tabs {
      text-align: left;
      margin-bottom: 5rem; } }
  .pane-clinical-services-groups .wcm-cs-tabs .tab-wrapper {
    display: inline-block;
    background-color: #f7f7f7;
    border-radius: 2.5rem;
    padding: 0.4rem; }
    .pane-clinical-services-groups .wcm-cs-tabs .tab-wrapper button {
      color: #555555;
      background-color: #f7f7f7;
      border: none;
      border-radius: 2.25rem;
      padding: 0.5rem 2rem 0.5rem 2rem;
      font-size: 1.3rem; }
      @media screen and (min-width: 992px) {
        .pane-clinical-services-groups .wcm-cs-tabs .tab-wrapper button {
          padding: 0.5rem 4rem 0.75rem 4rem;
          font-size: 1.5rem; } }
      .pane-clinical-services-groups .wcm-cs-tabs .tab-wrapper button.active {
        color: #fff;
        background-color: #b31b1b; }
      .pane-clinical-services-groups .wcm-cs-tabs .tab-wrapper button:focus {
        outline: none; }

.pane-clinical-services-groups .service-list {
  text-align: left;
  list-style-type: circle; }
  .pane-clinical-services-groups .service-list li {
    font-size: 1.4rem;
    margin-top: 0;
    margin-bottom: 1.5rem;
    break-inside: avoid-column;
    padding-right: 1em; }
    .pane-clinical-services-groups .service-list li a {
      border-bottom: none;
      color: #211d1d; }
      .pane-clinical-services-groups .service-list li a:hover {
        color: #e7751d;
        border-bottom: 1px solid; }
  @media screen and (min-width: 992px) {
    .pane-clinical-services-groups .service-list {
      column-count: 2; } }

.pane-clinical-services-groups .category-container {
  margin-bottom: 10rem; }
  @media screen and (min-width: 992px) {
    .pane-clinical-services-groups .category-container .category-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 5rem;
      position: relative; } }
  .pane-clinical-services-groups .category-container .category-row .services-container {
    display: flex;
    flex-flow: column; }
    @media screen and (min-width: 992px) {
      .pane-clinical-services-groups .category-container .category-row .services-container {
        flex: 0 1 calc(50% - 2.5rem); } }
    .pane-clinical-services-groups .category-container .category-row .services-container.standard {
      position: relative; }
      @media screen and (min-width: 992px) {
        .pane-clinical-services-groups .category-container .category-row .services-container.standard .inside-wrapper .service-list {
          display: none; }
        .pane-clinical-services-groups .category-container .category-row .services-container.standard .inside-wrapper:hover {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          right: 0;
          z-index: 1; }
          .pane-clinical-services-groups .category-container .category-row .services-container.standard .inside-wrapper:hover .service-list {
            display: block; } }
    @media screen and (min-width: 992px) {
      .pane-clinical-services-groups .category-container .category-row .services-container.fullwidth .inside-wrapper .service-list {
        display: none; }
      .pane-clinical-services-groups .category-container .category-row .services-container.fullwidth .inside-wrapper:hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
        z-index: 1; }
        .pane-clinical-services-groups .category-container .category-row .services-container.fullwidth .inside-wrapper:hover .service-list {
          display: block; } }
  .pane-clinical-services-groups .category-container .category-row .inside-wrapper {
    background-color: #fbf9f7;
    border: 1px solid #fbf9f7;
    padding: 3rem 3rem 3rem 3rem;
    text-align: center;
    transition: all 0.4s ease-in-out;
    border-radius: 1rem;
    margin-bottom: 2rem; }
    @media screen and (min-width: 992px) {
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper {
        margin-bottom: 5rem;
        min-height: 210px; } }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .category-icon {
      order: 1;
      fill: #b31b1b;
      color: #b31b1b;
      opacity: 1;
      margin-left: auto;
      margin-right: auto;
      width: 3.5rem;
      height: 3.5rem; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .category-name-description {
      order: 2;
      transform: translateY(0);
      transition: all 0.3s ease; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .category-name {
      font-family: "1898Sans-Regular", sans-serif;
      color: #211d1d;
      font-size: 2.1rem;
      text-align: center;
      margin-top: 1rem; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .category-description {
      font-family: "1898Sans-Regular", sans-serif;
      color: #83171d;
      font-size: 1.3rem;
      text-align: center; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .service-list {
      order: 4;
      display: none;
      margin-top: -4rem; }
      @media screen and (min-width: 992px) {
        .pane-clinical-services-groups .category-container .category-row .inside-wrapper .service-list {
          opacity: 0; } }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper .service-count {
      order: 3;
      flex-shrink: 1;
      color: #b31b1b;
      margin-bottom: 0;
      margin-top: 0.5rem; }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper .service-count .count-wrapper {
        display: inline-block;
        background-color: #f8f2ee;
        border-radius: 1.5rem;
        padding: 0.25rem 3rem; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover {
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #b21a1b;
      box-shadow: 0 0 2.5rem 0 rgba(0, 0, 0, 0.1); }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .category-icon {
        opacity: 0; }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .category-name-description {
        transform: translateY(-5.5rem); }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .category-name {
        color: #b21a1b; }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .category-description {
        color: #787878; }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .service-list {
        order: 3;
        display: block; }
        @media screen and (min-width: 992px) {
          .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .service-list {
            opacity: 1; } }
      .pane-clinical-services-groups .category-container .category-row .inside-wrapper:hover .service-count {
        order: 4;
        opacity: 0;
        display: none; }
    .pane-clinical-services-groups .category-container .category-row .inside-wrapper.standard .category-icon {
      transition: all 0.3s ease; }

.pane-clinical-services-groups .alpha-container {
  display: flex;
  flex-direction: column; }
  .pane-clinical-services-groups .alpha-container .alpha-jump {
    margin-top: 2rem; }
    @media screen and (min-width: 992px) {
      .pane-clinical-services-groups .alpha-container .alpha-jump {
        display: flex;
        flex-direction: row; } }
    .pane-clinical-services-groups .alpha-container .alpha-jump .go-to {
      color: #666666;
      display: block;
      text-align: center; }
      @media screen and (min-width: 992px) {
        .pane-clinical-services-groups .alpha-container .alpha-jump .go-to {
          flex: 0 0 0%;
          flex-basis: initial;
          padding-right: 2rem; } }
    .pane-clinical-services-groups .alpha-container .alpha-jump .letter-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start; }
      @media screen and (min-width: 992px) {
        .pane-clinical-services-groups .alpha-container .alpha-jump .letter-wrapper {
          flex: 1 0 0%;
          flex-wrap: nowrap;
          justify-content: space-around; } }
      .pane-clinical-services-groups .alpha-container .alpha-jump .letter-wrapper .letter {
        width: 11.1%;
        text-align: center; }
        @media screen and (min-width: 992px) {
          .pane-clinical-services-groups .alpha-container .alpha-jump .letter-wrapper .letter {
            width: auto; } }
  .pane-clinical-services-groups .alpha-container .services-wrapper {
    flex-grow: 1;
    margin-top: 2rem; }
    .pane-clinical-services-groups .alpha-container .services-wrapper .services-container {
      background-color: #fbf9f7;
      border: 1px solid #fbf9f7;
      padding: 1.5rem;
      border-radius: 1rem;
      margin-bottom: 2rem; }
      .pane-clinical-services-groups .alpha-container .services-wrapper .services-container .header-wrapper .letter-header {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #b31b1b;
        background-color: #f8f2ee;
        font-weight: bold;
        font-size: 2.2rem;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        margin: 0; }
      .pane-clinical-services-groups .alpha-container .services-wrapper .services-container .service-list {
        margin-top: 2rem;
        margin-bottom: 0;
        padding-left: 3rem; }
        @media screen and (min-width: 992px) {
          .pane-clinical-services-groups .alpha-container .services-wrapper .services-container .service-list {
            padding-left: 6rem; } }
